import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import routes from '@/router/index'
import 'swiper/css/swiper.css'
import './utils/rem.js'
Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(ElementUI);
const router = new VueRouter({routes});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
